import React, { createContext } from 'react';

import useAuth from './authHooks';

const AuthContext = createContext();

function AuthProvider({children}){

    const { logar, logout, loading, userLog, recovery } = useAuth();

    return (
        <AuthContext.Provider value={{authenticated: !!userLog, logar, logout, loading, userLog, recovery}}>
            {children}
        </AuthContext.Provider>
    )

}

export { AuthContext, AuthProvider }