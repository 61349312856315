import React from "react";
import ReactDOM from "react-dom";
import Routes from "./config/routes";
import { AuthProvider } from "./authContext";

import { QueryClient, QueryClientProvider } from 'react-query';

import "./assets/css/material-dashboard-react.css?v=1.9.0";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CssBaseline } from "@material-ui/core";

// import MyThemeProvider from './components/MyThemeProvider';

// import { SettingsProvider } from './contexts/SettingsContext';

// import { createTheme, ThemeProvider } from "@material-ui/core";

// const theme = createTheme({
//   overrides: {
//     // Style sheet name ⚛️
//     MuiButton: {
//       // Name of the rule
//       text: {
//         // Some CSS
//         background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
//         borderRadius: 3,
//         border: 0,
//         color: "white",
//         height: 48,
//         padding: "0 30px",
//         boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
//       },
//     },
//   },
// });

const queryClient = new QueryClient()

ReactDOM.render(
  // <ThemeProvider theme={theme}>
  <QueryClientProvider client={queryClient}>
    <React.Fragment>
      {/* <SettingsProvider>
        <MyThemeProvider> */}
      <AuthProvider>
        <ToastContainer
          autoClose={2000}
          position="top-center"
          className="toast-container"
          toastClassName="dark-toast"
        />
        <CssBaseline />
        <Routes />
      </AuthProvider>
      {/* </MyThemeProvider>
      </SettingsProvider> */}
    </React.Fragment>
  </QueryClientProvider>,
  // </ThemeProvider>,
  document.getElementById("root")
);
