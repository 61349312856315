import axios from 'axios';

import { BASE_URL } from '../../constants';

let headers = {
    ContentType: 'application/json',
    Accept: 'application/json',
};

const token = localStorage.getItem('access_token');

if (token) {
    headers.Authorization = `Bearer ${token}`
}

const api = axios.create({
    baseURL: BASE_URL,
    headers,
    responseType: 'json',
    // timeout: 50,
});

// Adicionar um interceptor de solicitação
api.interceptors.request.use(function (config) {

    // Faça algo antes que a solicitação seja enviada

    return config;

}, function (error) {
    // Faça algo com erro de solicitação
    return Promise.reject(error);

});

// let isRefreshing = false;
// let subscribers = [];

// Adicionar um interceptor de resposta
api.interceptors.response.use(function (response) {

    // Qualquer código de status dentro do intervalo de 2xx faz com que esta função seja acionada
    // Faça algo com os dados de resposta
    return response;

}, function (error) {

    // Quaisquer códigos de status que caiam fora do intervalo de 2xx fazem com que esta função seja acionada
    // Faça algo com erro de resposta

    if (axios.isAxiosError(error) && !error.response) {
        return Promise.reject('O sistema está indisponível devido a problemas de rede. Por favor, verifique sua conexão e tente novamente.');
    }

    const { response: { status, data } } = error;

    const tokenMessage = ["token expired", "token not found", "token invalid"];

    if (status === 401 && tokenMessage.indexOf(data.message) > -1) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        window.location.href = "/login";
    }

    return Promise.reject(error);

});

export default api;