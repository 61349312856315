import React from "react";
import api from "../../config/connection";
import Service from "../../service";

const service = Service.getInstance();

export default function useAuth() {

  const [loading, setLoading] = React.useState(false);
  const [userLog, setUserLog] = React.useState(null);

  React.useEffect(() => {
    function loadStorage() {
      const user = localStorage.getItem("user");
      if (user) {
        setUserLog(JSON.parse(user));
      }
      setLoading(false);
    }

    loadStorage();
  }, []);

  const recovery = async (email) => {
    return await service.post("secure/auth/recovery", email);
  }

  async function logar(auth) {

    try {

      setLoading(true);

      const {
        data: { access_token, user },
      } = await service.post("secure/auth/login", auth);

      localStorage.setItem("access_token", JSON.stringify(access_token));
      localStorage.setItem("user", JSON.stringify(user));

      setUserLog(user);

      api.defaults.headers.Authorization = `Bearer ${access_token}`;
      
      setLoading(false);

      return { access: true, data: null };

    } catch (error) {
      setLoading(false);
      return { access: false, data: error.response };
    }
  }

  async function logout() {

    try {

      await service.get("secure/auth/logout");

      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
      setUserLog(null);
      return { access: true, data: null }

    } catch (error) {
      return { access: false, data: error.response };
    }
  }

  return { logar, logout, loading, userLog, recovery };
  
}
