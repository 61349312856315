import React, { Suspense } from 'react'
import { BrowserRouter, Redirect, Route, Link as RouterLink, Switch } from 'react-router-dom'
import { Backdrop, Box, Breadcrumbs, CircularProgress, Container, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { isAuthenticated } from "../../store"
import { ErrorBoundary } from 'react-error-boundary'

// const Page404 = React.lazy(() => import('../../pages/page404'))
const Page500 = React.lazy(() => import('../../pages/page500'))

const Admin = React.lazy(() => import('./../../components/admin'))

const Home = React.lazy(() => import('../../public/home'))
const SecretariaPublica = React.lazy(() => import('../../public/home/secretaria'))
const Objetivo = React.lazy(() => import('../../public/home/secretaria/objetivo'))
const Estrutura = React.lazy(() => import('../../public/home/secretaria/estrutura'))
const Equipe = React.lazy(() => import('../../public/home/secretaria/equipe'))
const Expediente = React.lazy(() => import('../../public/home/secretaria/expediente'))
const Galeria = React.lazy(() => import('../../public/home/secretaria/galeria'))
const Proposito = React.lazy(() => import('../../public/home/secretaria/propositos'))
const Atos = React.lazy(() => import('../../public/home/secretaria/atos'))
const Agenda = React.lazy(() => import('../../public/home/agenda'))
const Catalogo = React.lazy(() => import('../../public/home/catalogo'))
const Servico = React.lazy(() => import('../../public/home/servico'))
const DetailsServico = React.lazy(() => import('../../public/home/servico/details'))
const ServicoSecretaria = React.lazy(() => import('../../public/home/secretaria/servico'))
const TiposServicos = React.lazy(() => import('../../components/TiposServicos'))
const Auth = React.lazy(() => import('../../public/auth'))
// import equipeTeste from '../../public/home/equipeTeste';

function CustomRouter({ isPrivate, ...rest }) {

  // const { authenticated } = useContext(AuthContext);

  if (isPrivate && !isAuthenticated()) {
    return <Redirect to="/" />
  }
  return <Route {...rest} />
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Routes() {
  const classes = useStyles()
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserRouter>
        <Switch>
          <Suspense fallback={
            <Backdrop className={classes.backdrop} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          }>

            <CustomRouter path="/error" component={Page500} />
            {/* <CustomRouter path='*' component={Page404} /> */}

            <CustomRouter exact path="/" component={Servico} />
            <CustomRouter path="/tipos" component={TiposServicos} />
            <CustomRouter path="/agenda" component={Agenda} />
            <CustomRouter path="/contatos" component={Catalogo} />
            <CustomRouter exact path="/secretarias" component={Home} />
            <CustomRouter path="/servicos/:idServico" component={DetailsServico} />
            <CustomRouter exact path="/secretaria/:id" component={SecretariaPublica} />
            <CustomRouter path="/secretaria/:id/objetivo" component={Objetivo} />
            <CustomRouter path="/secretaria/:id/estrutura" component={Estrutura} />
            <CustomRouter path="/secretaria/:id/equipe" component={Equipe} />
            <CustomRouter path="/secretaria/:id/expediente" component={Expediente} />
            <CustomRouter path="/secretaria/:id/galeria" component={Galeria} />
            <CustomRouter exact path="/secretaria/:id/servicos" component={ServicoSecretaria} />
            <CustomRouter path="/secretaria/:id/servicos/:idServico" component={DetailsServico} />
            <CustomRouter path="/secretaria/:id/proposito" component={Proposito} />
            <CustomRouter path="/secretaria/:id/atos" component={Atos} />
            <CustomRouter path="/login" component={Auth} />

            {/* <CustomRouter path="/teste" component={quipeTeste */}

            <CustomRouter path="/admin" component={Admin} />

          </Suspense>
        </Switch>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export function SimpleBreadcrumbs() {
  return (
    <Route>
      {({ location }) => {
        const pathnames = location.pathname.split("/").filter(x => x);
        return (
          <Breadcrumbs aria-label="Breadcrumb">
            <RouterLink color="inherit" to="/">
              Home
            </RouterLink>
            {pathnames.map((value, index) => {
              const last = index === pathnames.length - 1;
              const to = `/${pathnames.slice(0, index + 1).join("/")}`;

              return last ? (
                <Typography color="textPrimary" key={to}>
                  {value}
                </Typography>
              ) : (
                <RouterLink color="inherit" to={to} key={to}>
                  {value}
                </RouterLink>
              );
            })}
          </Breadcrumbs>
        );
      }}
    </Route>
  );
}

function ErrorFallback({ error, resetErrorBoundary }) {

  return (

    <React.Fragment>
      <Container maxWidth="lg" component={Paper} style={{ marginTop: 50 }}>
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '60vh' }}>
          <Box>
            <Typography component="div" variant="h6" align="center">
              <Box lineHeight="normal" fontWeight="fontWeightBold">
                Algo deu errado:
              </Box>
              <Box fontSize={14} p={2}>Infelismente algo deu errado. Se o problema persistir. Contate o administrador do sistema.</Box>
            </Typography>
            <p align="center" style={{ background: '#DCDCDC', padding: 10, boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' }}><code>{`Descrição do erro: ${error.message}`}</code></p>
          </Box>
        </Box>
      </Container>
    </React.Fragment>

  )
}

export default Routes;